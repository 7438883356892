import React from "react"
import { graphql } from "gatsby"
import {
  Banner,
  Theme,
  IconFamily,
  IconFiles,
  IconGuardianship,
  IconHealthServices,
  IconCare,
  IconEmergency,
  CardBar,
  ReturnToTop,
  ResourcesTile,
  IconSheets,
  IconClipboard,
  IconBaby,
  IconAdoption,
  IconSchooling,
  IconAdd,
  IconHeart,
  IconKinship,
  IconLegal,
  IconClinical,
  IconMentalHealth,
  IconHousingAlt,
  IconShower,
  IconSpeechAlt,
  Tile,
  ReadMoreLink,
  Section,
  Container,
  Row,
  Box,
  Heading
} from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"

import Layout from "../../../components/foster-care/Layout"
import WrappedHelmet from "../../../components/foster-care/WrappedHelmet"

const { fosterCareThemeFull } = Theme

interface CarerResourcesFormatted {
  title: string
  category: string
  url: string
}

interface CarerResource {
  link: string
  document: {
    file: {
      url: string
    }
  }
  title: string
  category: string
}

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    carerResources: {
      edges: [
        {
          node: CarerResource
        }
      ]
    }
  }
}

const subNav = [
  {
    title: "General resources",
    id: "general-resources",
    icon: "Family"
  },
  {
    title: "Policies",
    id: "policies",
    icon: "Files"
  },
  {
    title: "Support for carers",
    id: "support-for-carers",
    icon: "Guardianship"
  },
  {
    title: "Therapeutic care",
    id: "therapeutic-care",
    icon: "HealthServices"
  },
  {
    title: "Foster care phases",
    id: "foster-care-phases",
    icon: "Care"
  },
  {
    title: "Safety",
    id: "Safety",
    icon: "Emergency"
  }
]

const cardBarIconSelector = (
  type: string,
  color: string,
  height: string,
  width: string
) => {
  if (type === "Family") {
    return (
      <IconFamily
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  if (type === "Files") {
    return (
      <IconFiles
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  if (type === "Guardianship") {
    return (
      <IconGuardianship
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  if (type === "HealthServices") {
    return (
      <IconHealthServices
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  if (type === "Care") {
    return (
      <IconCare
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  if (type === "Emergency") {
    return (
      <IconEmergency
        className="center dib"
        color={color}
        height={height}
        width={width}
      />
    )
  }
  return undefined
}

const TasPage = ({
  location,
  data: {
    site: { siteMetadata },
    carerResources
  }
}: Props) => {
  const carerResourcesFormatted: CarerResourcesFormatted[] =
    carerResources.edges
      .map(({ node }) => {
        const { link, document, title, category } = node
        return {
          title,
          category,
          url: document && document.file.url ? document.file.url : link
        }
      })
      .filter((e) => e.url)

  const getResourcesForCategory = (
    category: string,
    resources: CarerResourcesFormatted[]
  ) =>
    resources
      .filter((resource) => resource.category === category)
      .map(({ title, url }) => ({
        name: title,
        reference: url
      }))

  return (
    <div>
      <WrappedHelmet
        title={`Carer Resource Library | ${siteMetadata.title}`}
        description="A library created for quick and easy access to support documentation for foster carers"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={fosterCareThemeFull.medium}
      />
      <Layout>
        <Banner title="Carer Resource Library" />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>Welcome aboard!</Heading>
              <p>
                We’re thrilled to have you join our team of incredibly
                passionate people; staff, foster and kinship carers, and health
                specialists who, like you, are motivated by a desire to make a
                real and lasting positive difference in a child’s life.{" "}
              </p>
              <p>
                The ability to care for a child is a gift. On behalf of the
                thousands of children in Australia who are unable to live safely
                with their family at this time, we thank you.
              </p>
              <p>
                This library has been created to give you quick and easy access
                to all the documentation you need for your carer journey. You
                can also access the Carer guide for more general information and
                support.{" "}
              </p>
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l">
              <Tile ribbon>
                <div className="ma3 ma4-ns pv3-l">
                  <Heading size={2} className="color-lwb-theme-medium">
                    Carer Guide
                  </Heading>
                  <p className="copy mt3 mb4">
                    Frequently asked questions by our carer community on a range
                    of topics.
                  </p>
                  <ReadMoreLink
                    text="Go to Carer Guide"
                    href="/foster-care/support/carer-guide/"
                    className="color-lwb-theme-darker"
                  />
                </div>
              </Tile>
            </Box>
          </Row>
        </Container>
        <div id="CardBarNav">
          <Section background="bg-hex-image">
            <Container>
              <Row className="justify-center">
                <Box className="w-80-l">
                  <Heading size={2} className="mb4">
                    Quick navigation
                  </Heading>
                  <CardBar
                    items={subNav}
                    selectIconFn={cardBarIconSelector}
                    rowWidth={{ large: 3, medium: 3 }}
                  />
                </Box>
              </Row>
            </Container>
          </Section>
        </div>
        <div id="general-resources">
          <Section background="bg-lwb-theme-xxx-light">
            <Container>
              <Row className="relative">
                <Box className="layout-readable">
                  <Heading size={2}>General resources</Heading>
                  <p>
                    This is where you can find general information about Life
                    Without Barriers, as well as local/state information for
                    your journey as a carer. Important forms are also located
                    here for your easy access.
                  </p>
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Resources"
                    icon={
                      <IconSheets
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "General resources - Resources",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Forms"
                    icon={
                      <IconClipboard
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "General resources - Forms",
                      carerResourcesFormatted
                    )}
                  />
                  <div className="pt5_5 pt5-ns">
                    <ReturnToTop targetId="CardBarNav" />
                  </div>
                </Box>
              </Row>
            </Container>
          </Section>
        </div>
        <div id="policies">
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>Policies</Heading>
                <p>
                  This section covers all the policies around care, codes of
                  conduct and information like baby sitting, medication and
                  water safety.
                </p>
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Caring For Children In Out Of Home Care"
                  icon={
                    <IconBaby height="25" color={fosterCareThemeFull.medium} />
                  }
                  resources={getResourcesForCategory(
                    "Policies - Caring For Children In Out Of Home Care",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Your Role As A Foster Carer"
                  icon={
                    <IconAdoption
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Policies - Your Role As A Foster Carer",
                    carerResourcesFormatted
                  )}
                />
                <div className="pt5_5 pt5-ns">
                  <ReturnToTop targetId="CardBarNav" />
                </div>
              </Box>
            </Row>
          </Container>
        </div>
        <div id="support-for-carers">
          <Section background="bg-lwb-theme-xxx-light">
            <Container>
              <Row className="relative">
                <Box className="layout-readable">
                  <Heading size={2}>Support for carers</Heading>
                  <p>
                    This section covers support for carers in aspects of ongoing
                    training, respite and financial support.
                  </p>
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Training"
                    icon={
                      <IconSchooling
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Support for carers - Training",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Financial"
                    icon={
                      <IconAdd height="25" color={fosterCareThemeFull.medium} />
                    }
                    resources={getResourcesForCategory(
                      "Support for carers - Financial",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Carer Support"
                    icon={
                      <IconHeart
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Support for carers - Carer Support",
                      carerResourcesFormatted
                    )}
                  />
                  <div className="pt5_5 pt5-ns">
                    <ReturnToTop targetId="CardBarNav" />
                  </div>
                </Box>
              </Row>
            </Container>
          </Section>
        </div>
        <div id="therapeutic-care">
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>Therapeutic care</Heading>
                <p>
                  This section covers resources relating to the CARE model that
                  guides our work with children and their families.
                </p>
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Care"
                  icon={
                    <IconGuardianship
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Therapeutic care - Care",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Therapeutic Crisis Intervention For Families"
                  icon={
                    <IconAdoption
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Therapeutic care - Therapeutic Crisis Intervention For Families",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Pace And Repair Parenting"
                  icon={
                    <IconClinical
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Therapeutic care - Pace And Repair Parenting",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Fight, Flight, Freeze"
                  icon={
                    <IconEmergency
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Therapeutic care - Fight, Flight, Freeze",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Sexual Development"
                  icon={
                    <IconMentalHealth
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Therapeutic care - Sexual Development",
                    carerResourcesFormatted
                  )}
                />
                <div className="pt5_5 pt5-ns">
                  <ReturnToTop targetId="CardBarNav" />
                </div>
              </Box>
            </Row>
          </Container>
        </div>
        <div id="foster-care-phases">
          <Section background="bg-lwb-theme-xxx-light">
            <Container>
              <Row className="relative">
                <Box className="layout-readable">
                  <Heading size={2}>Foster care phases</Heading>
                  <p>
                    Information organised for you that cover the different
                    stages you go through when caring for a child.
                  </p>
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Placement Matching And Welcoming A Child"
                    icon={
                      <IconGuardianship
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Foster care phases - Placement Matching And Welcoming A Child",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Family And Cultural Relationships"
                    icon={
                      <IconKinship
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Foster care phases - Family And Cultural Relationships",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Disclosures And Allegations"
                    icon={
                      <IconLegal
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Foster care phases - Disclosures And Allegations",
                      carerResourcesFormatted
                    )}
                  />
                  <ResourcesTile
                    className="mt4 mt5-ns lwb-shadow w-100"
                    name="Leaving Care And Ending Placements"
                    icon={
                      <IconCare
                        height="25"
                        color={fosterCareThemeFull.medium}
                      />
                    }
                    resources={getResourcesForCategory(
                      "Foster care phases - Leaving Care And Ending Placements",
                      carerResourcesFormatted
                    )}
                  />
                  <div className="pt5_5 pt5-ns">
                    <ReturnToTop targetId="CardBarNav" />
                  </div>
                </Box>
              </Row>
            </Container>
          </Section>
        </div>
        <div id="Safety">
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>Safety</Heading>
                <p>
                  This section covers resources relating to the safety of
                  children. It highlights safety for fire, water, vehicle and
                  online safety.
                </p>
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Home Safety"
                  icon={
                    <IconHousingAlt
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    // This resource name hasn't been updated inline with the RecourceTile name due to the time and complexity involved in changing it in Contentful and that Carer Resources for Tasmanian is a trial section of the website.
                    "Safety - Pace And Repair Parenting",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Fire Safety"
                  icon={
                    <IconEmergency
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Safety - Fire Safety",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Water Safety"
                  icon={
                    <IconShower
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Safety - Water Safety",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Car Seats And Safety"
                  icon={
                    <IconBaby height="25" color={fosterCareThemeFull.medium} />
                  }
                  resources={getResourcesForCategory(
                    "Safety - Car Seats And Safety",
                    carerResourcesFormatted
                  )}
                />
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Online Safety"
                  icon={
                    <IconSpeechAlt
                      height="25"
                      color={fosterCareThemeFull.medium}
                    />
                  }
                  resources={getResourcesForCategory(
                    "Safety - Online Safety",
                    carerResourcesFormatted
                  )}
                />
                <div className="pt5_5 pt5-ns">
                  <ReturnToTop targetId="CardBarNav" />
                </div>
              </Box>
            </Row>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    carerResources: allContentfulCarerResourceItem {
      edges {
        node {
          link
          document {
            file {
              url
            }
          }
          title
          category
        }
      }
    }
  }
`

export default TasPage
